<!--
 * @Author: goodLiang
 * @Date: 2023-02-23 09:36:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-18 13:35:55
 * @FilePath: /business-cms/src/views/shop/detail.vue
-->
<template>
  <div class="app-container">
    <CustomCard>
      <template #cardTitle>
        <div class="page-heading">
          <h3>认证信息</h3>
        </div>
      </template>
      <el-divider></el-divider>
      <el-form
        ref="formDataRef"
        :model="formData"
        label-width="130px"
        label-position="left"
        :disabled="true"
      >
        <SubHead> 店铺基本信息</SubHead>

        <el-form-item label="店铺名称" prop="shop_name">
          <el-col :span="11">
            <el-input name="shop_name" v-model="formData.shop_name" />
          </el-col>
        </el-form-item>
        <el-form-item label="主营类目">
          <el-col :span="11">
            <div>酒类</div>
          </el-col>
        </el-form-item>

        <SubHead> 开店人基本信息</SubHead>

        <el-row :gutter="20">
          <el-col :span="7" :offset="0">
            <el-form-item label="上传身份证像" prop="corprate_pic2_image">
              <el-image
                style="width: 100px; height: 100px"
                :src="formData.oss_legal_licence_front_url"
              ></el-image>
              &nbsp;&nbsp;
              <el-image
                style="width: 100px; height: 100px"
                :src="formData.oss_legal_licence_back_url"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="姓名" prop="corprate_name">
          <el-col :span="11">
            <el-input name="corprate_name" v-model="formData.contact_name" />
          </el-col>
        </el-form-item>
        <el-form-item label="身份证号" prop="corprate_cert_no">
          <el-col :span="11">
            <el-input
              name="corprate_cert_no"
              maxlength="18"
              v-model="formData.legal_licence_no"
            />
          </el-col>
        </el-form-item>

        <template v-if="formData.account_type != 3">
          <el-form-item label="联系人手机号码" prop="operator_mobile">
            <el-col :span="11">
              <el-input
                name="operator_mobile"
                v-model="formData.contact_mobile"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="operator_email">
            <el-col :span="11">
              <el-input
                name="operator_email"
                v-model="formData.contact_email"
              />
            </el-col>
          </el-form-item>
          <div class="sub-heading">
            <div class="sub-heading__title">个体工商户信息</div>
            <div class="sub-heading__desc">（暂时只支持境内企业入驻）</div>
          </div>
          <el-form-item label="企业名称" prop="customer_name">
            <el-col :span="11">
              <el-input name="customer_name" v-model="formData.sign_name" />
            </el-col>
          </el-form-item>
          <el-form-item label="企业简称" prop="customer_short_name">
            <el-col :span="11">
              <el-input
                name="customer_short_name"
                v-model="formData.short_name"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="营业执照" prop="customer_cert_image">
            <el-col :span="11">
              <el-image
                style="width: 100px; height: 100px"
                :src="formData.oss_licence_url"
              ></el-image>
            </el-col>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="customer_cert_no">
            <el-col :span="11">
              <el-input name="customer_cert_no" v-model="formData.licence_no" />
            </el-col>
          </el-form-item>
          <el-form-item label="营业执照注册地址" prop="customer_address">
            <el-col :span="11">
              <!-- <el-input name="customer_address" v-model="formData.customer_address" /> -->
              {{ formData.province_name }}{{ formData.city_name
              }}{{ formData.district_name }}{{ formData.address }}
            </el-col>
          </el-form-item>
          <!-- <el-form-item label="营业执照起始日期" prop="customer_cert_expiry">
                        <el-col :span="11">
                            <el-input name="customer_cert_expiry" v-model="formData.customer_cert_expiry" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="营业执照截止日期" prop="customer_cert_expiry_end">
                        <el-col :span="11">
                            <el-input name="customer_cert_expiry_end" v-model="formData.customer_cert_expiry_end" />
                        </el-col>
                    </el-form-item> -->
        </template>
        <SubHead> 发票信息</SubHead>

        <el-form-item label="纳税人分类" prop="taxpayer_type">
          <el-col :span="11">
            <el-select v-model="formData.taxpayer" class="i-block">
              <el-option
                v-for="item in shopOptions.taxpayerType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoice_type">
          <el-col :span="11">
            <el-select v-model="formData.invoice_type" class="i-block">
              <el-option
                v-for="item in shopOptions.invoiceType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="银行账户类型" prop="bank_card_type">
          <el-col :span="11">
            <el-select v-model="formData.bank_account_type" class="i-block">
              <el-option
                v-for="(item, index) in shopOptions.bankCardType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="
                  (formData.account_type == 1 && index == 0) ||
                  (formData.account_type == 3 && index == 1)
                "
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="结算银行账户" prop="bank_account_no">
          <el-col :span="11">
            <el-input name="bank_account_no" v-model="formData.bank_card_no" />
          </el-col>
        </el-form-item>
        <el-form-item label="账户持有人姓名" prop="account_name">
          <el-col :span="11">
            <el-input name="account_name" v-model="formData.account_name" />
          </el-col>
        </el-form-item>
        <el-form-item label="结算开户行" prop="bank_name">
          <el-col :span="11">
            <el-input
              name="bank_name"
              v-model="formData.bank_name"
              readonly
              disabled
            />
          </el-col>
        </el-form-item>
        <el-form-item label="开户行支行" prop="bank_number">
          <el-col :span="11">
            <el-select v-model="formData.sub_bank_name" class="i-block" value-key="bank_number">
              <el-option
                v-for="item in subBankList"
                :key="item.bank_number"
                :label="item.sub_bank_name"
                :value="item"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="手机号" prop="corprate_mobile">
          <el-col :span="11">
            <!-- <el-input name="corprate_mobile" v-model="formData.mobile" /> -->
            {{ formData.mobile || formData.contact_mobile }}
          </el-col>
        </el-form-item>
      </el-form>
    </CustomCard>
  </div>
</template>

<script>
import { to } from 'await-to-js'
import { getShopDetail } from '@/api/shop'
import shopOptions from '@/const/shop'
export default {
  data() {
    return {
      formData: {},
      shopOptions,
      subBankList: []
    }
  },
  created() {
    if (this.$route.params.id) {
      this._initData(this.$route.params.id)
    }
  },
  mounted() {},
  methods: {
    async _initData(id) {
      const [, res] = await to(getShopDetail({ seller_id: id }))
      if (res) {
        if (!res.contact_name) {
          res.contact_name = res.sign_name || res.short_name
        }
        res.contact_mobile ? null : (res.contact_mobile = res.mobile)
        this.formData = res
        this.subBankList.push({ bank_number: res.bank_number, sub_bank_name: res.sub_bank_name })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sub {
  &-heading {
    position: relative;
    margin: 30px 0 16px;
    display: flex;
    align-items: center;

    &__title {
      font-size: 16px;
      font-weight: bold;
    }

    &__desc {
      font-size: 14px;
    }
  }

  &-actions {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 1;
    margin: auto;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

.i-block {
  width: 100%;
}
</style>
